<template>
  <div>
    <span v-if="header === 'Storage From' || header === 'Storage End'" @click="modalControl(true)"></span>
    <p v-else-if="header === 'Basket'" @click="modalControl(true)" class="basket">
      {{ getAllBagsCount(isArrival) }} bag(s), EUR {{ priceCalculation(isArrival) }}
    </p>
    <p v-else-if="header === 'Contact us'" @click="modalControl(true)" class="contact-us-text">
      {{ header }}
      <span class="contact-us-text__phone">+45 53 700 777</span>
    </p>
    <p v-else @click="modalControl(true)" class="basic"> {{ fieldName }}</p>
    <transition name="fade" appear>
      <div class="modal-overlay" v-if="showModal" @click="modalControl(false)"></div>
    </transition>
    <transition name="fade" appear>
      <div v-if="header === 'Basket' && showModal" :class="{pbasket_content: header==='Basket'}"></div>
    </transition>
    <transition name="slide" appear>
      <div
        class="modal"
        :class="{
          'popup_basket': header==='Basket',
          'modal-full': fullModal
        }"
        v-if="showModal"
        ref="modalContent"
      >
        <span class="close-btn" @click="modalControl(false)">X</span>
        <h2>
          {{ header }}
        </h2>
        <br v-if="header !== 'Contact us'"><br>
        <slot v-if="header === 'Storage From'">
          <p>The expected time interval that you will hand-in luggage to us.</p><br>
          <p>If you arrive by plane and provide your
            fight number, we can track any delay and
            wait for you.</p><br>
          <p>Regular service hours are 8 am to 7 pm.</p>
        </slot>
        <slot v-else-if="header === 'Storage End'">
          <p>The time interval that you will collect luggage from us.</p><br>
          <p>Regular service hours are 8 am to 7 pm.</p>
        </slot>
        <slot v-else-if="header === 'Contact us'">
          <p class='contact-us-p'>Name*</p>
          <input placeholder="Name" class="card-inputs-style" type="text" v-model="contactForm.name" required><br><br>
          <p class='contact-us-p'>Email*</p>
          <input type="email" class="card-inputs-style" v-model="contactForm.email"
                @keyup="validateEmail(contactForm.email)" placeholder="Email" required><br>
          <div class="cards-block">
            <small v-if="!validEmail">Please enter a valid email</small>
          </div><br>
          <p class='contact-us-p'>Comment*</p>
          <textarea class="card-inputs-style contact-us-textarea" rows='5' v-model="contactForm.comment"></textarea>
          <br><br>
          <button @click="sendFormInfoToBackend()"
                  :disabled="!contactForm.name || !contactForm.email || !validEmail || !contactForm.comment">
            Send
          </button>
        </slot>
        <slot v-else-if="header === 'Basket'">
          <h3>Your order details:</h3>
          <p class="basket-p bold small-pad"><br>Service</p>
          <span v-if="getOrderInfo.from && getOrderInfo.to" class="basket-span">Luggage transport from {{ getOrderInfo.from }} to {{ getOrderInfo.to }}</span>
          <span v-else class="basket-span">
            Storage
            <span v-if="getAllBagsCount(isArrival) === 1">for {{ getAllBagsCount(isArrival) }} bag</span>
            <span v-if="getAllBagsCount(isArrival) > 1">for {{ getAllBagsCount(isArrival) }} bags</span>
            <span v-if="getStorageDays === 1"> for {{ getStorageDays }} day</span>
            <span v-if="getStorageDays > 1"> for {{ getStorageDays }} days</span>
          </span>
          <slot v-if="getOrderInfo.arrivalDate || getStorageInfo.storageDateFrom ||
                      getOrderInfo.arrivalTime || getStorageInfo.storageTimeFrom">
            <p v-if="getOrderInfo.from === 'Airport'" class="basket-p"><br>Arrival at {{ getOrderInfo.from }}</p>
            <p v-else-if="getOrderInfo.to === 'Airport'" class="basket-p"><br>Luggage hand out in {{ getOrderInfo.to }}</p>
            <p
              v-else-if="(getOrderInfo.from !== 'Hotel' && getOrderInfo.to !== 'Cruise') && (getOrderInfo.from !== 'Cruise' && getOrderInfo.to !== 'Hotel')"
              class="basket-p"
            >
              <br>Storage start time:
            </p>
            <span
              class="basket-span"
              v-if="(getOrderInfo.from !== 'Hotel' || getOrderInfo.to !== 'Cruise') && (getOrderInfo.from !== 'Cruise' || getOrderInfo.to !== 'Hotel')"
            >
              {{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate :
                getStorageInfo.storageDateFrom
              }}<br>
              At {{ getOrderInfo.arrivalTime ? getOrderInfo.arrivalTime : getStorageInfo.storageTimeFrom }}<br>
            </span>
            <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Hotel'">
              <br><br>Delivery location:
              <span class="basket-span"><br>{{ getOrderInfo.hotelName }}<br><br></span>
              <span class="basket-span" v-if="getOrderInfo.hotelName">We will deliver your bags to the hotel reception by 3 pm (for arrivals before 10 am)</span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Cruise'">
              <br><br>Delivery location:
              <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Private Address'">
              <br><br>Delivery location:
              <span class="basket-span">
                <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
                <br>2 days before planned delivery you will get a 2-hour timeslot for delivery at your address.
              </span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.hotelName }} {{ getOrderInfo.hotelroom ? '(' + getOrderInfo.hotelroom + ')' : '' }}
                <br>Leave the bags at the reception by 8.30 am
              </span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Airport'">
              <br><br>Pick-up location:
              <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Airport'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
                <br>2 days before planned pick-up you will get a 2-hour time slot for pickup at your location, 6.30 am - 11 am
              </span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Cruise'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.hotelName }}
                <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
                <br>Leave bags at the reception by 8.30 am
              </span>
              <br><br>Delivery location:
              <span class="basket-span"><br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}</span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Hotel'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
                <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
              </span>
              <br><br>Delivery location:
              <span class="basket-span"><br>{{ getOrderInfo.hotelName }}<br><br></span>
              <span class="basket-span" v-if="getOrderInfo.hotelName">
                We will deliver your bags to the hotel reception by 3 pm (for arrivals before 10 am)
              </span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Private Address'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
                <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
              </span>
              <br><br>Delivery location:
              <span class="basket-span">
                <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
                <br>2 days before planned delivery you will get a 2-hour timeslot for delivery at your address.
              </span>
            </p>
            <p class="basket-p" v-if="getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Cruise'">
              <br><br>Pick-up location:
              <span class="basket-span">
                <br>{{ getOrderInfo.streetNumberPostal }} {{ getOrderInfo.apartment ? '(' + getOrderInfo.apartment + ')' : '' }}
                <br>{{ getOrderInfo.arrivalDate ? getOrderInfo.arrivalDate : '' }}
              </span>
              <br><br>Delivery location:
              <span class="basket-span">
                <br>{{ getOrderInfo.cruiseShip }} {{ getOrderInfo.stateroom ? '(' + getOrderInfo.stateroom + ')' : '' }}
                <br>2 days before planned delivery you will get a 2-hour time slot for delivery at your location.
              </span>
            </p>
          </slot>
          <slot v-if="getStorageInfo.storageDateEnd || getStorageInfo.storageTimeEnd">
            <p class="basket-p"><br><br>Storage end time:</p>
            <span class="basket-span">
              {{ getStorageInfo.storageDateEnd }}<br>
              At {{ getStorageInfo.storageTimeEnd }}<br>
            </span>
          </slot>
          <hr>
          <p class="basket-p"><strong>Price</strong><br></p>
          <div class="basket-span" v-if="getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags">
            <span>1st night,</span>
            <span v-if="getAllBagsCount(isArrival) === 1"> {{ getAllBagsCount(isArrival) }} bag in total,</span>
            <span v-if="getAllBagsCount(isArrival) > 1"> {{ getAllBagsCount(isArrival) }} bags in total,</span>
            <span> EUR {{ getStateData.additionalInfo.bagsStoragePriceFirstNight }}</span>
          </div>
          <div class="basket-span" v-if="!getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags">
            {{ getAllBagsCount(isArrival) }} bag(s), EUR {{ getStateData.additionalInfo.bagsPrice }}
          </div>
          <span class="basket-span italic" v-if="$route.path === '/storage-info' || getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags">
            EUR {{ getStateData.constants.VALUE1_STORAGE }}/bag
          </span>
          <span class="basket-span italic"
                v-if="getOrderInfo.from === 'Private Address' || getOrderInfo.to === 'Private Address'">
            EUR {{ getStateData.constants.VALUE1_PRIVATE }}/bag
          </span>
          <template v-if="!(getUrlPrices.bagPrice || getUrlPrices.bagHeavyPrice)">
          <span class="basket-span italic"
                v-if="getOrderInfo.from !== 'Private Address' && getOrderInfo.to !== 'Private Address' &&
                ($route.path !== '/storage-info' && !getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags)">
            EUR {{ getStateData.constants.VALUE1_AIRPORT }}/bag
          </span>
          <span class="basket-span italic" v-if="$route.path !== '/storage-info' && !getStorageInfo.numberOfBags && !getStorageInfo.numberOfHeavyBags">- min price 3 bags</span>
          </template>
          <template v-else>
            <span class="basket-span italic">
              EUR {{ getUrlPrices.bagPrice || getStateData.constants.VALUE1_AIRPORT}}/bag
            </span>
          </template>
          <div class="basket-span"
            v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
            {{ getStorageInfo.numberOfHeavyBags > 0 ?
              getStorageInfo.numberOfHeavyBags : getOrderInfo.numberOfHeavyBags }} Oddsize, EUR
            {{ getStateData.additionalInfo.oddSizeBagsPrice }}
          </div>
          <template v-if="!(getUrlPrices.bagPrice || getUrlPrices.bagHeavyPrice)">
          <span class="basket-span italic"
                v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
            <!--Handling fee is EUR 5.00 per bag<br>-->
            Handling fee is EUR {{ getUrlPrices.bagHeavyPrice || getStateData.constants.VALUE2 }} per oddsize<br>
          </span>
          </template>
          <template v-else>
          <span class="basket-span italic"
                v-if="getStorageInfo.numberOfHeavyBags > 0 || getOrderInfo.numberOfHeavyBags > 0">
            Handling fee is EUR {{ getUrlPrices.bagHeavyPrice || getStateData.constants.VALUE2 }} per oddsize<br>
          </span>
          </template>
          <div v-if="(getStorageInfo.numberOfBags || getStorageInfo.numberOfHeavyBags) && getStorageDays > 1">
            <div class="basket-span">
              {{ getStorageDays - 1 }} extra night(s) EUR {{ getStateData.additionalInfo.bagsPrice - getStateData.additionalInfo.bagsStoragePriceFirstNight }}
            </div>
            <span class="basket-span italic">
              EUR 10.00 per bag per extra night<br>
            </span>
          </div>
          <div class="basket-span" v-if="getStateData.additionalInfo.lateFee">
            Late ordering fee, EUR {{ getStateData.additionalInfo.lateFee }}
          </div>
          <span class="basket-span italic" v-if="getStateData.additionalInfo.lateFee">
            Handling fee for orders placed 2 days or less before day of service<br><br>
          </span>
          <h3 class="total-price" v-if="priceCalculation(isArrival) > 0">Total Price<br></h3>
          <div class="basket-p mt" v-if="priceCalculation(isArrival) > 0">
            <strong>EUR {{ priceCalculation(isArrival) }}</strong>
          </div>
          <div class="basket-span italic black" v-if="priceCalculation(isArrival) > 0">
            Including 25% VAT and credit card fee
          </div>
        </slot>
        <slot v-else-if="header === 'Transfer Day'">
          <div v-for="(transferDayText, index) in transferDayPopupInfo" :key="index">
            <p>{{ transferDayText }}</p><br>
          </div>
        </slot>
        <slot v-else-if="header === 'Transfer Day (to Airport)'">
          <div v-for="(transferDayText, index) in transferDayToAirportPopupInfo" :key="index">
            <p>{{ transferDayText }}</p><br>
          </div>
        </slot>
        <slot v-else-if="header === 'Arrival Time'">
          <div v-for="(arrivalTimeInfoText, index) in arrivalTimePopupInfo" :key="index">
            <p>{{ arrivalTimeInfoText }}</p><br>
          </div>
        </slot>
        <slot v-else-if="header === 'Cruise ship name'">
          <p>{{ cruiseShipNamePopupInfo }}</p><br>
        </slot>
        <slot v-else-if="header !== 'Some delivery info'">
          <p>{{ bagsPopupInfo[0] }}</p>
          <ul v-for="bagPopupInfo in bagsPopupInfo" :key="bagPopupInfo">
            <li v-if="bagPopupInfo !== bagsPopupInfo[0]">{{ bagPopupInfo }}</li><br>
          </ul>
        </slot>
        <slot v-else>
          <p>{{ deliveryPopupInfo }}</p>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import additionalFunctions from '../mixins/additionalFunctions'
export default {
  name: 'Popup',
  props: ['header', 'fieldName', 'isArrival'],
  computed: {
    ...mapGetters(['getUrlPrices', 'getOrderInfo', 'getStorageInfo', 'getStateData', 'getAllBagsCount', 'priceCalculation', 'getCloseAllPopups', 'getStorageDays']),
    isSafari () {
      return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification))
    },
    fullModal () {
      return this.$route.path === '/terms-and-conditions' || this.$route.path === '/payment-form'
    }
  },
  mixins: [additionalFunctions],
  data () {
    return {
      contactForm: {
        name: '',
        email: '',
        comment: ''
      },
      validEmail: true,
      showModal: '',
      bagsPopupInfo: [
        'We differentiate between',
        'Ordinary bags, which are normal size bags, and',
        'Odd-size luggage, which is luggage with ODD dimensions (such as bikes or golf bags).',
        'All bags must be less than 30 kg / 66 lbs'
      ],
      deliveryPopupInfo: 'If you need delivery outside of Copenhagen, you can contact us via the Contact Us form below',
      transferDayPopupInfo: [
        'We will pick up luggage between 7 am and 11 am, but you will receive a specific 2 hr-time slot two days before.',
        'You can request a specific time slot by contacting us directly. This carries an extra fee.'
      ],
      transferDayToAirportPopupInfo: [
        'Select the time you will pick up your luggage from our office in Copenhagen airport.',
        'We recommend minimum 2 hours before your flight departure.'
      ],
      arrivalTimePopupInfo: [
        'State the time you expect to hand in the luggage to us.',
        'We do our utmost to service you if your flight is delayed.'
      ],
      cruiseShipNamePopupInfo: 'State the name of the cruise ship you arrive on.'
    }
  },
  created () {
    this.addScrollToPopup()
  },
  methods: {
    modalControl (control) {
      if (this.header === 'Basket' && document.documentElement.scrollWidth > 800) {
        this.showModal = false
      } else {
        this.showModal = control
        if (control) {
          document.body.classList.add('modal-open')
        } else {
          document.body.classList.remove('modal-open')
        }
      }
    },
    async sendFormInfoToBackend () {
      await fetch(`${process.env.VUE_APP_PROD_MODE === 'true'
        ? process.env.VUE_APP_API_SERVER
        : 'http://localhost:3100'}/contact-us`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...this.contactForm })
      }).then(response => {
        if (response.status === 200) {
          this.modalControl(false)
          Object.keys(this.contactForm).map(key => { this.contactForm[key] = '' })
        } else {
          alert('Sorry, something went wrong!')
        }
      })
    }
  },
  watch: {
    showModal (newVal, oldVal) {
      if (newVal) {
        document.querySelector('html').style.overflow = 'hidden'
        setTimeout(() => {
          const modalContent = this.$refs.modalContent.offsetHeight - 460 > 0 ? this.$refs.modalContent.offsetHeight - 460 : 0
          document.querySelector('.contact-us-textarea').style.height = `${110 + modalContent}px`
        }, 0)
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
    },
    getCloseAllPopups (newVal, oldVal) {
      if (newVal === true) {
        this.showModal = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/popup";

  .mt {
    margin-top: 5px;
  }

  .basket-span.black {
    color: rgb(26, 26, 26);
    font-family: 'BlissMedium';
    letter-spacing: 0.02rem;
  }

  @media (min-width: 1000px) {
    .popup_basket {
      width: 33%;
      background-color: #e4e4e4;
      left: 80%;
    }
    .pbasket_content {
      position: fixed;
      top: 95px;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      z-index: 99;
    }
  }

  .total-price {
    margin-top: 10px;
  }

  .modal .bold {
    font-weight: 700;
  }

  .modal .small-pad {
    margin-bottom: 2px;
  }

  .modal > .basic {
    font-size: 20px;
    font-weight: 400;
  }

  .modal > .basic, .modal > ul {
    margin-left: 30px
  }

  div > .basic:first-child::after {
    padding-left: 10px;
    font-family: FontAwesome;
    content: "\f05a";
    cursor: pointer;
    pointer-events: all;
  }

  // div > span::after {
  //   position: relative;
  //   top: -30px;
  //   left: 100%;
  //   font-family: FontAwesome;
  //   content: "\f05a";
  //   cursor: pointer;
  // }

  // @media screen and (max-width: 414px) {
  //   div > span::after {
  //     left: 330px;
  //   }
  // }

  // @media screen and (max-width: 375px) {
  //   div > span::after {
  //     left: 290px;
  //   }
  // }

  // @media screen and (max-width: 360px) {
  //   div > span::after {
  //     left: 280px;
  //   }
  // }

  // @media screen and (max-width: 350px) {
  //   div > span::after {
  //     left: 240px;
  //   }
  // }

  // @media screen and (max-width: 310px) {
  //   div > span::after {
  //     left: 200px;
  //   }
  // }

  .contact-us-text {
    padding: 0.5em 1em;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.07rem;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &__phone {
      margin-left: 10px;

      &::before {
        padding-left: 10px;
        font-family: FontAwesome;
        content: "\f095";
        cursor: pointer;
        pointer-events: all;
      }
    }
  }

  .contact-us-text::before {
    padding-left: 10px;
    font-family: FontAwesome;
    content: "\f0e0";
    cursor: pointer;
    pointer-events: all;
  }

  .contact-us-p {
    margin: 0px 5px;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.07rem;
    color: #ffffff;
    text-transform: uppercase;
  }

  .card-inputs-style {
    padding: 0 15px;
    width: 100%;
  }

  textarea.card-inputs-style {
    padding-top: 15px;
  }

  @media screen and (max-width: 600px) {
    .card-inputs-style {
      width: 95%;
    }
  }

  .contact-us-text::after {
    display: none;
  }

  textarea {
    border: 2px solid #cccccc;
  }

  .card-inputs-style {
    text-align: left;
    padding: 0 15px;
  }

  button {
    width: 100%;
    height: 40px;
    background: #ababab;
    border: 2px solid #53565A;
    border-radius: 5px;
    font-family: BlissLight;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  button:disabled {
    background-color:rgba(#ababab, .5);
    cursor: not-allowed;
  }

  .basket {
    width: auto;
    height: 3em;
    display: block;
    padding: 0.8em 1em;
    margin: 0px 20px 10px 0px;
    border: 1px solid #cccccc;
    color: #fff;
    text-align: center;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.07rem;
    @media (min-width: 800px) {
      width: 300px;
    }
    @media (max-width: 800px) {
      cursor: pointer;
    }
    @media screen and (min-width: 1435px) {
      margin: 0;
    }
  }

  .basket::after {
    top: 40px;
    padding-left: 5px;
    font-family: FontAwesome;
    content: "\f291";
    color: #ffffff;
    cursor: pointer;
  }

  .basket-p {
    font-family: BlissLight;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.07rem;
    color:#000000;
  }

  .basket-span {
    font-family: BlissLight;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 0.07rem;
    text-transform: initial;
    color: #53565A;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .italic {
    font-style: italic;
    color: #797b7e;
    line-height: 20px;
  }

  .basket-span::after {
    content: "";
  }

  @media screen and (max-width: 484px) {
    .header-container {
      max-height: 85px;
    }
    .basket {
      font-size: 13px;
      padding: 0.8em 0.4em 0.8em 0.4em;
      letter-spacing: 0;
    }
  }

  @media screen and (max-width: 360px) {
    .basket {
      font-size: 11px;
      letter-spacing: 0.03rem;
    }
  }

  @media screen and (max-width: 316px) {
    .basket {
      font-size: 10px;
      letter-spacing: 0.02rem;
    }

    .basket::after {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 280px) {
    .basket {
      font-size: 10px;
      letter-spacing: 0.01rem;
    }
  }
</style>
