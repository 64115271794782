export const hsStart = 3 // high season month start, 0 = January, 11 = December
export const hsEnd = 8 // 8 = september
export const hsMonthTestStart = 1 // february for testing
export const hsMonthTestEnd = 2 // March for testing

export var glbHighSeason = false

export const func = {
  glbTest: (data) => {
    return 'tester'
  },
  getHighSeasonStart: (x) => {
    return hsStart
  },
  getHighSeasonEnd: (x) => {
    return hsEnd
  },
  getHighSeason: (x) => {
    // x is the date the order should start, if =NaN then current date is used
    // higseason from june to september (month 5-8), defined globally
    // var t = this.getLocalTime()
    // var highseason = (t.getMonth() >= this.$highseasonStart && t.getMonth() <= this.$highseasonEnd)
    // console.log('high start: ' + hsStart)
    var t = x === undefined ? new Date() : new Date(x)
    var highseason = (t.getMonth() >= hsStart && t.getMonth() <= hsEnd) || (t.getMonth() >= hsMonthTestStart && t.getMonth() <= hsMonthTestEnd)
    glbHighSeason = highseason
    return highseason
  },
  getLocalTime: () => {
    var date = new Date()
    var t = new Date(date.toLocaleString('en-US', {
      timeZone: 'Europe/Copenhagen' // 'Europe/Copenhagen' // timeZone: 'America/New_York'
    }))
    return t
  }
}
