<template>
  <div>
    <span>
      <select
        class="for-popup input_full-width"
        v-model="$data[timePickerId === 1 ? 'arrivalTime' : (timePickerId === 2 ? 'storageTimeFrom' : (timePickerId === 3 ? 'storageTimeEnd' : ''))]"
        @change="time"
        aria-required="true"
        :id="`timePicker-${timePickerId}`"
      >
        <option class="disoption" value="" disabled selected>{{ disOption }}</option>
        <option v-for="time in getTime(timePickerId)" class="option" :key="time">{{ time }}</option>
      </select>
      <Popup v-if="timePickerId === 2 || timePickerId === 3" :fieldName='""'
             :header='timePickerId === 2 ? "Storage From" : (timePickerId === 3 ? "Storage End" : "")'/>
    </span><br><br>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Popup from '@/components/Popup'
import $ from 'jquery'

export default {
  name: 'TimePicker',
  props: ['timePickerId', 'disOption', 'infoFrom', 'infoTo'],
  components: { Popup },
  computed: mapGetters(['getOrderInfo', 'getStorageInfo']),
  data () {
    return {
      arrivalTime: '',
      storageTimeFrom: '',
      storageTimeEnd: '',
      storageTime: '',
      highseason: false,
      // Winter season times
      times_null: [''],
      times: [
        '8:30 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm', '12 pm - 1 pm',
        '1 pm - 2 pm', '2 pm - 3 pm', '3 pm - 4 pm',
        '4 pm - 4:30 pm',
        'Other'
      ],
      timesAir: [
        '1 pm - 2 pm', '2 pm - 3 pm', '3 pm - 4 pm',
        '4 pm - 4.30 pm',
        'Other'
      ],
      timesAirHotel: [
        '8.30 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm',
        '12 pm - 1 pm', '1 pm - 2 pm',
        'Other'
      ],
      timesAirCruise: [
        '8.30 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', 'Other'
      ],
      timesAirPrivate: [ // also private to airport
        '8.30 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm',
        '12 pm - 1 pm', '1 pm - 2 pm',
        'Other'
      ],
      timesPrivateAir: [ // also private to airport
        '1 pm - 2 pm', '2 pm - 3 pm',
        '3 pm - 4 pm', '4 pm - 4:30 pm',
        'Other'
      ],
      // High season times
      times_high: [
        '7 am - 8 am', '8 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm', '12 pm - 1 pm',
        '1 pm - 2 pm', '2 pm - 3 pm', '3 pm - 4 pm',
        '4 pm - 5 pm', '5 pm - 6 pm', '6 pm - 7 pm',
        'Other'
      ],
      timesAir_high: [
        '1 pm - 2 pm', '2 pm - 3 pm', '3 pm - 4 pm',
        '4 pm - 5 pm', '5 pm - 6 pm', '6 pm - 7 pm',
        'Other'
      ],
      timesAirCruise_high: [
        '7 am - 8 am', '8 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', 'Other'
      ],
      timesAirHotel_high: [
        '7 am - 8 am', '8 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm',
        '12 pm - 1 pm', '1 pm - 2 pm',
        'Other'
      ],
      timesAirPrivate_high: [
        '7 am - 8 am', '8 am - 9 am', '9 am - 10 am',
        '10 am - 11 am', '11 am - 12 pm',
        '12 pm - 1 pm', '1 pm - 2 pm',
        'Other'
      ],
      timesPrivateAir_high: [
        '1 pm - 2 pm', '2 pm - 3 pm',
        '3 pm - 4 pm', '4 pm - 5 pm', '5 pm - 6 pm', '6 pm - 7 pm',
        'Other'
      ]
    }
  },
  created () {
    if (this.getOrderInfo.arrivalTime) {
      this.arrivalTime = this.getOrderInfo.arrivalTime
    }

    if (this.getStorageInfo.storageTimeFrom) {
      this.storageTimeFrom = this.getStorageInfo.storageTimeFrom
    }

    if (this.getStorageInfo.storageTimeEnd) {
      this.storageTimeEnd = this.getStorageInfo.storageTimeEnd
    }
  },
  methods: {
    ...mapMutations(['addTime']),
    time () {
      switch (this.timePickerId) {
        case 1:
          if (this.arrivalTime !== 'Other') {
            this.addTime({
              arrivalTime: this.arrivalTime
            })
          } else {
            this.addTime({
              arrivalTime: ''
            })
          }
          break
        case 2:
          if (this.storageTimeFrom !== 'Other') {
            this.addTime({
              storageTimeFrom: this.storageTimeFrom
            })
          } else {
            this.addTime({
              storageTimeFrom: '' // this.storageTimeFrom
            })
          }
          break
        case 3:
          if (this.storageTimeEnd !== 'Other') {
            this.addTime({
              storageTimeEnd: this.storageTimeEnd
            })
          } else {
            this.addTime({
              storageTimeEnd: ''
            })
          }
          break
      }
    },
    getTime (timePickerId) {
      console.log('***** Entering gettime() function')
      var oldseason = this.highseason
      var userDate = timePickerId === 1 ? this.getOrderInfo.arrivalDate : (timePickerId === 2 ? this.getStorageInfo.storageDateFrom : this.getStorageInfo.storageDateEnd)
      this.highseason = this.$func.getHighSeason(userDate)
      var newtime = []
      //
      // Check individual cases of services
      //
      // Hotel - Airport and Cruise - Airport
      if ((this.infoFrom === 'Hotel' && this.infoTo === 'Airport') || (this.infoFrom === 'Cruise' && this.infoTo === 'Airport')) {
        console.log('1 - enter: ' + this.infoFrom + ' / ' + this.infoTo)
        newtime = this.highseason ? this.timesAir_high : this.timesAir
      } else if (this.infoFrom === 'Airport' && this.infoTo === 'Hotel') { // Airport - Hotel
        console.log('2 - enter: ' + this.infoFrom + ' / ' + this.infoTo)
        newtime = this.highseason ? this.timesAirHotel_high : this.timesAirHotel
      } else if ((this.infoFrom === 'Private Address' && this.infoTo === 'Airport')) { // Private - Airport
        console.log('3 - enter: ' + this.infoFrom + ' / ' + this.infoTo)
        newtime = this.highseason ? this.timesPrivateAir_high : this.timesPrivateAir
      } else if ((this.infoFrom === 'Airport' && this.infoTo === 'Private Address')) { // Airport - Private
        console.log('3.1 - enter: ' + this.infoFrom + ' / ' + this.infoTo)
        newtime = this.highseason ? this.timesAirPrivate_high : this.timesAirPrivate
      } else if ((this.infoFrom === 'Airport' && this.infoTo === 'Cruise')) { // Airport - Cruise
        console.log('3.2 - enter: ' + this.infoFrom + ' / ' + this.infoTo)
        newtime = newtime = this.highseason ? this.timesAirCruise_high : this.timesAirCruise
      } else { // Storage, Airport-Private (Hotel-Cruise and Cruise-Hotel does not have a timepicker)
        /* console.log('enter-4: ' + this.infoFrom)
        console.log('enter-4: ' + this.infoTo)
        console.log('xx: ' + this.getOrderInfo.from + ' / ' + this.getOrderInfo.to)
        */
        this.storageTime = timePickerId === 2 ? this.storageTimeFrom : this.storageTimeEnd
        newtime = this.highseason ? this.times_high : this.times
        if (this.highseason !== oldseason) {
          if (timePickerId === 2) this.addTime({ storageTimeFrom: '' })
          else this.addTime({ storageTimeEnd: '' })
          oldseason = this.highseason
          this.storageTime = ''
          timePickerId === 2 ? this.storageTimeFrom = '' : this.storageDateEnd = ''
          $('#timePicker-' + timePickerId).css('color', 'red')
        }
        return newtime
      }
      // Continue for not storage
      if (this.highseason !== oldseason) {
        this.addTime({ arrivalTime: '' })
        oldseason = this.highseason
        this.arrivalTime = ''
        this.arrivalTime = ''
        $('#timePicker-' + timePickerId).css('color', 'red')
      }
      return newtime
    }
  },
  watch: {
    arrivalTime: {
      handler: function (val) {
        if (val === 'Other') {
          alert('Contact office for special hours')
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else if (val === '') {
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else {
          $(`#timePicker-${this.timePickerId}`).css('color', 'black')
        }
      }
    },
    storageTimeFrom: {
      handler: function (val) {
        if (val === 'Other') {
          alert('Contact office for special storage hours')
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else if (val === '') {
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else {
          $(`#timePicker-${this.timePickerId}`).css('color', 'black')
        }
      }
    },
    storageTimeEnd: {
      handler: function (val) {
        if (val === 'Other') {
          alert('Contact office for special storage hours')
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else if (val === '') {
          $(`#timePicker-${this.timePickerId}`).css('color', 'red')
        } else {
          $(`#timePicker-${this.timePickerId}`).css('color', 'black')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  input, select {
    width: 20em;
    line-height: 3em;
    border: 1px solid #cccccc;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 1px #cccccc;
    text-align: center;
    color:#727272;
  }

  select {
    height: 40px;
    cursor: pointer;
  }

  // .disoption {
  //   color: rgb(139, 139, 139);
  // }

  // option:disabled {
  //   color: rgb(139, 139, 139);
  // }

  option {
    background-color: #d4d5d6;
    margin: 6em 1em 1em 1em;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.1rem;
    color:#727272;
  }
</style>
